import React from 'react';
import { Intro } from './components/Intro';
import { Team } from './components/Team';
import { AboutUs } from './components/AboutUs';
import { Contact } from './components/Contact';

export const App = () => {
  return (
    <div className='max-w-screen min-h-screen overflow-x-hidden'>
      <Intro />
      <AboutUs />
      <Team />
      <Contact />
    </div>
  )
}