import React from "react";

export const Contact = () => {
  return (
    <div className="w-screen h-screen flex flex-col bg-[#F1F3F6]">
      <div className="self-center w-5/6 h-full bg-[#F1F3F6] flex flex-col justify-center space-y-20">
        <p className="text-2xl font-bold self-center text-center">We are thrilled to empower every organization and individual to learn more about the world</p>
        <div className="self-center bg-black h-fit w-fit rounded-3xl">
          <p className="px-4 py-2 text-xl text-white">Contact Us</p>
        </div>
      </div>

        <div className="bottom-0 border-t-2 h-8 border-black">
        <p className="text-sm">©️ MirareMeld. 2023. All rights reserved</p>
        </div>
      </div>

  )
}