import React from "react";

export const Team = () => {
  return (
    <div className="w-screen h-screen bg-white grid place-items-center">
      <div className="flex flex-col justify-around w-full h-5/6 px-28">
        <span className="text-5xl font-semibold self-center">Founders</span>
        <div className="flex justify-between w-full">
          <div className=" grid place-items-center space-y-10">
            <div className="min-h-fit min-w-fit w-32 h-32 bg-black rounded-full"></div>
            <span className="">Alvis</span>
            <p>CEO</p>
            <p>Lorem Ipsum</p>
          </div>
          <div className=" grid place-items-center space-y-10">
            <div className="min-h-fit min-w-fit w-32 h-32 bg-black rounded-full"></div>
            <span className="">Fryderyk</span>
            <p>CFO</p>
            <p>Lorem Ipsum</p>
          </div>
          <div className="grid place-items-center space-y-10">
            <div className="min-h-fit min-w-fit w-32 h-32 bg-black rounded-full"></div>
            <span className="">Marcus</span>
            <p>CTO</p>
            <p>Lorem Ipsum</p>
          </div>

        </div>
        <div />
      </div>
    </div>
  )
}