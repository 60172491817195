import React from "react";

export const AboutUs = () => {
  return (
    <div className='flex w-screen h-screen bg-[#F1F3F6]'>
      <div className="flex flex-col justify-center w-3/5 h-full">
        <div className="flex flex-col w-4/5 h-4/5 self-center justify-center">
          <p className="text-3xl font-bold">New way to see the world</p>
          <br/>
          <p className="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel aliquam quam. Sed congue tempus molestie. Duis sagittis leo eu mollis eleifend. Nunc quis mollis nibh, vel venenatis ante. Vestibulum viverra ut enim imperdiet venenatis. Suspendisse potenti. Vivamus malesuada magna vitae rutrum dapibus. Praesent posuere augue nec mi sodales, sit amet fringilla risus scelerisque. Maecenas tincidunt viverra libero, sit amet auctor nibh finibus eget. Proin malesuada accumsan lacus nec sagittis. Vestibulum vel nunc iaculis, dictum urna eu, convallis orci. Quisque condimentum vel mi nec aliquet.</p>
          <br/>
          <div className="flex flex-col space-y-10">
            <p>Camera</p>
            <p>Screen</p>
            <p>Machine Learning</p>
          </div>
        </div>
      </div>
    </div>
  )
}