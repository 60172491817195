import React from "react";

export const Intro = () => {
  return (
    <div className='flex flex-col w-screen h-screen bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 justify-between text-white'>
      <div className="self-center flex w-5/6 mt-16 h-14 justify-around">
        <div className="flex h-full items-center basis-1/6">
          <span className="text-2xl font-bold">MirareMeld</span>
        </div>
        <div className="flex-1 flex text-2xl justify-around">
            <p className="self-center">Home</p>
            <p className="self-center">About Us</p>
            <p className="self-center">Teams</p>
        </div>
        <div className="self-center flex basis-1/6 justify-end">
          <div className="grid items-center w-fit h-fit items-center border-solid border-white border-2 rounded-3xl bg-[#CFCFCF]/20">
            <p className="text-center px-4 py-2 text-md font-semibold">Contact Us</p>
          </div>
        </div>
      </div>
      <div className="w-1/2 h-1/4 self-center space-y-16 flex flex-col">
        <p className="text-5xl text-center">A new way to see, to feel, and to learn the world</p>
        <div className="self-center bg-white rounded-3xl">
          <p className="px-9 py-3 text-black font-semibold">Learn More</p>
        </div>
      </div>
      <div />
    </div>
  )
}